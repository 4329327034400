import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormGroup from "../FormGroup";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import axios from "axios";
import { businessEmailValidate } from "../../helpers";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[6-9]\d{9}$/;
export default function WaitListForm({ setSuccess, isGetEarlyAccess }: any) {
  const { handleSubmit, control, setError } = useForm();
  const [loading, setLoading] = useState(false);

  function onSubmit(contact: any) {
    // const fs = (window as any).freshsales;
    if (!businessEmailValidate(contact.email)) {
      setError("email", { type: "notBusinessEmail" }, { shouldFocus: true });
      return;
    }
    const user_properties = {
      // fs_contact: true,
      // fs_update: false,
      first_name: contact.first_name,
      last_name: contact.last_name,
      mobile_number: contact.mobile_number,
      email: contact.email,
      custom_field: {
        cf_annual_revenue: "",
        cf_business_name: contact.business_name,
        cf_buyer_or_seller: contact.buyerOrSeller,
        cf_waitlist: isGetEarlyAccess ? "4" : "1",
      },
    };
    // console.log(user_properties)
    // if (fs) {
    //     fs.identify(user_properties.Email, user_properties, () => {
    //         setSuccess(true)
    //         setLoading(false);
    //     }, () => setLoading(false))
    // } else {
    //     setLoading(false)
    // }
    setLoading(true);
    axios
      .post("/api/waitlist", user_properties)
      .then(() => {
        // console.log(JSON.stringify(response.data));
        setSuccess(true);
      })
      .catch(function (error) {
        console.error(error, error.data);
      })
      .finally(() => setLoading(false));
  }

  return (
    <form
      id={"waitlist-form-main"}
      name={"waitlist-form-main"}
      onSubmit={handleSubmit(onSubmit)}
      className={"flex flex-wrap  justify-between"}
    >
      <div className="flex flex-col sm:flex-row w-full justify-between sm:gap-8">
        <FormGroup
          name={"first_name"}
          control={control}
          label={"First Name"}
          rules={{ required: true }}
        />
        <FormGroup
          name={"last_name"}
          control={control}
          label={"Last Name"}
          rules={{ required: true }}
        />
      </div>
      <FormGroup
        hidden={true}
        defaultValue={"true"}
        name={"waitlist"}
        control={control}
        label={""}
      />
      <div className="flex flex-col sm:flex-row w-full justify-between sm:gap-8">
        <FormGroup
          prepend={"+91"}
          type={"number"}
          name={"mobile_number"}
          control={control}
          label={"Phone Number"}
          rules={{
            required: true,
            maxLength: 10,
            minLength: 10,
            pattern: phoneRegex,
          }}
        />
        <FormGroup
          name={"email"}
          control={control}
          label={"Email"}
          rules={{ required: true, pattern: emailRegex }}
        />
      </div>
      <div className="flex flex-col sm:flex-row w-full justify-between sm:gap-8">
        <FormGroup
          options={[
            { name: "Select", code: undefined },
            {
              code: "seller",
              name: "I sell SaaS",
            },
            { code: "buyer", name: "I buy SaaS" },
            { code: "explorer", name: "I am exploring SaaSPay" },
          ]}
          name={"buyerOrSeller"}
          control={control}
          label={"Are you a Seller or a Buyer?"}
          rules={{ required: true }}
        />
        <FormGroup
          name={"business_name"}
          control={control}
          label={"Company Name"}
          rules={{ required: true }}
        />
      </div>

      <Button
        id={"waitlist-form-button"}
        type={"submit"}
        disabled={loading}
        className={
          "mt-3 sm:mt-0 mb-6 w-full md:rounded-xl py-[14px] sm:py-[16px]"
        }
        variant={"primary"}
      >
        <Text
          text={loading ? "Submitting. Hold Tight!" : "Join the Waitlist"}
          className={"inter-500-16-24 !text-white "}
        />
        {!loading && (
          <svg
            className={"ml-2.5"}
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.75L15.25 7M15.25 7L10 12.25M15.25 7L1.75 7"
              stroke="#F9FAFB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Button>
    </form>
  );
}
