import React from "react";
import Text from "../Text";
import { useController } from "react-hook-form";
import Dropdown from "../Dropdown";
import config from "../../config.json";
const { ERROR_MESSAGES }: any = config;
type PROPS_STYLE = {
  label: string;
  name: string;
  rules?: any;
  placeholder?: string;
  options?: { name: string; code: string | undefined }[];
  type?: string;
  control: any;
  textarea?: boolean;
};

export default function ContactUsFormGroup(props: PROPS_STYLE) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  });
  let invalidClass = " !border-[#fa9d9d] ";
  const INPUT_BASE_CLASS =
    "rounded-lg border-[1px] border-[#F3F3F3] focus:border-p1   px-3  w-full h-[58px] inter-600-16-29";
  return (
    <div className={"mb-3 md:mb-3 w-full"}>
      <div className="mb-2 md:mb-3">
        <Text textStyle={"b1"} text={props.label} />
      </div>
      <div className="">
        {props.options && (
          <Dropdown
            options={props.options}
            onChange={field.onChange}
            value={field.value}
            ref={field.ref}
            onBlur={field.onBlur}
            INPUT_BASE_CLASS={
              INPUT_BASE_CLASS + " " + (invalid && invalidClass)
            }
          />
        )}
        {props.options && (
          <input className="hidden" value={field.value} name={field.name} />
        )}

        {!props.options &&
          (!props.textarea ? (
            <input
              className={
                `${INPUT_BASE_CLASS} active:border-p1 outline-none placeholder:text-[#F3F3F3] ` +
                (invalid && invalidClass)
              }
              type={props.type || ""}
              placeholder={props.placeholder || props.label}
              {...field}
            />
          ) : (
            <textarea
              rows={4}
              className={
                `${INPUT_BASE_CLASS} !h-auto pt-2 active:border-p1 outline-none placeholder:text-[#F3F3F3] ` +
                (invalid && invalidClass)
              }
              placeholder={props.placeholder || props.label}
              {...field}
            />
          ))}
      </div>
      {error && (
        <div>
          <Text
            className={"text-[14px] !text-[#d00]"}
            text={ERROR_MESSAGES[error.type]}
          />
        </div>
      )}
    </div>
  );
}
