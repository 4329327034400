import React, { useState } from "react";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import Text from "../../atoms/Text";
import { useForm } from "react-hook-form";
import ContactUsFormGroup from "../../atoms/ContactUsFormGroup";
import Button from "../../atoms/Button";
import RevealAnimation from "../../atoms/RevealAnimation";
import axios from "axios";
import Modal from "../../atoms/Modal";
import WaitListModal from "../WaitlistModal";
import { businessEmailValidate } from "../../helpers";
import ContactUsCaricature from "../../images/contactus/ContactUsCaricature";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[6-9]\d{9}$/;
export default function ContactUsForm() {
  const { control, handleSubmit, reset, setError } = useForm();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  function onSubmit(contact: any) {
    // const fs = (window as any).freshsales;
    if (!businessEmailValidate(contact.email)) {
      setError("email", { type: "notBusinessEmail" }, { shouldFocus: true });
      return;
    }
    const user_properties = {
      // fs_contact: true,
      // fs_update: false,
      first_name: contact.name,
      last_name: "",
      mobile_number: contact.phone,
      email: contact.email,
      custom_field: {
        cf_message: contact.message,
        cf_buyer_or_seller: contact.buyerOrSeller,
        cf_waitlist: "2",
      },
    };
    // console.log(user_properties)
    // if (fs) {
    //     fs.identify(user_properties.Email, user_properties, () => {
    //         setSuccess(true)
    //         setLoading(false);
    //     }, () => setLoading(false))
    // } else {
    //     setLoading(false)
    // }
    setLoading(true);
    axios
      .post("/api/waitlist", user_properties)
      .then(() => {
        // console.log(JSON.stringify(response.data));
        setShowModal(true);
        reset({
          phone: "",
          buyerOrSeller: "",
          name: "",
          email: "",
          message: "",
        });
      })
      .catch(function (error) {
        console.error(error, error.data);
      })
      .finally(() => setLoading(false));
  }
  return (
    <Wrapper className={"bg-p2 px-4 xl:px-0 pt-[80px]"}>
      <Modal
        className={"!z-[100]"}
        width={"50%"}
        onClose={() => setShowModal(false)}
        show={showModal}
      >
        <WaitListModal
          success={true}
          setSuccess={() => {}}
          close={() => setShowModal(false)}
        />
      </Modal>
      <Container
        className={
          "flex py-5 pt-20 pb-20 md:pb-6 flex-wrap md:flex-nowrap justify-center md:justify-start"
        }
      >
        <div className="flex md:w-1/2 w-full flex-col">
          {/* <RevealAnimation animation={"slide-right"} className="mb-3">
            <Text
              component={"h1"}
              text={"Contact Us"}
              textStyle={"h2"}
              className="ml-[-3px]"
            />
          </RevealAnimation>
          <RevealAnimation
            animation={"slide-right"}
            className="mb-2 max-w-[463px]"
          >
            <Text
              text={"to be a part of India's first BNPL for SaaS"}
              textStyle={"s1"}
              className={"opacity-70"}
            />
          </RevealAnimation> */}
          {/* <RevealAnimation
            animation={"slide-right"}
            className="mb-3 max-w-[463px]"
          >
            <Text
              text={"You can also call us at "}
              textStyle={"s1"}
              className={"opacity-70"}
            />Expected Payment Frequency
            <a href={"tel:+9184473 30060"}>
              <Text
                text={"+918447330060"}
                textStyle={"s1"}
                className={"font-bold opacity-70"}
              />
            </a>
          </RevealAnimation> */}
          <RevealAnimation
            animation={"slide-right"}
            className="mt-0 md:mt-20 md:-ml-5"
          >
            <ContactUsCaricature />
          </RevealAnimation>
        </div>

        <form
          id={"contact-form"}
          name={"contact form"}
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-grow mt-3 md:mt-0 lg:ml-20 w-full md:w-auto"
        >
          <RevealAnimation
            animation={"slide-right"}
            className="md:ml-1 w-full flex flex-col items-center md:items-start"
          >
            <ContactUsFormGroup
              label={"Are you a Seller or a Buyer?"}
              name={"buyerOrSeller"}
              control={control}
              rules={{ required: true }}
              options={[
                { name: "Select", code: undefined },
                {
                  code: "seller",
                  name: "I sell SaaS",
                },
                { code: "buyer", name: "I buy SaaS" },
                { code: "explorer", name: "I am exploring SaaSPay" },
              ]}
            />
            <ContactUsFormGroup
              rules={{ required: true }}
              label={"Full Name"}
              name={"name"}
              control={control}
            />
            <ContactUsFormGroup
              rules={{ required: true, pattern: emailRegex }}
              label={"Work Email"}
              name={"email"}
              type={"email"}
              control={control}
            />
            <ContactUsFormGroup
              rules={{
                required: true,
                maxLength: 10,
                minLength: 10,
                pattern: phoneRegex,
              }}
              label={"Phone number"}
              name={"phone"}
              type={"tel"}
              control={control}
            />
            <ContactUsFormGroup
              textarea={true}
              label={"Message (Optional)"}
              name={"message"}
              type={"textarea"}
              control={control}
            />
            <Button
              disabled={loading}
              id={"contact-us-form"}
              type={"submit"}
              className={"bg-p1 mt-1 mb-24"}
              height={56}
              width={220}
              variant={"primary"}
            >
              <Text
                text={loading ? "Submitting. Hold Tight!" : "Submit"}
                textStyle={"inter-500-16-24"}
                className={"!text-white"}
              />
            </Button>
          </RevealAnimation>
        </form>
      </Container>
    </Wrapper>
  );
}
