import React from 'react';

export default function ContactUsCaricature(){
    return <svg className='w-full' version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 573 438" width="573" height="438">
	<g className='shape-3' id="person">
		<path id="Layer" fill="#f1f2f2" d="m64.4 327c-15.9-28.8-27-61-30.1-93.9-1.4-15.1-0.2-30.4 5.6-44.5 5.2-12.7 13.9-23.7 24.1-32.8 23.7-21.5 55.2-30.3 86.1-35.7 17-3 34.2-5.2 51.4-7.7l25.9-3.7 13.5-1.9c3.6-0.6 7.3-1.4 10.9-0.4 4.4 1.3 8.1 5.9 5.9 10.6-0.8 1.6-2.4 2.9-3.5 4.2q-2 2.3-3.9 4.6-7.4 8.9-14 18.4-2.7 3.8-5.2 7.8c18.6-16.2 38.7-30.5 60.1-42.8 2.5-1.5 5.4-1.3 7.5 0.9 1.8 1.9 2.2 5.6 0.2 7.5-18.5 17.6-35.2 37-49.9 57.8l5-5c5.9-5.8 11.7-11.7 17.5-17.4 4.9-4.7 11-8.7 17.9-5.7 3.4 1.5 4.3 6.2 1.9 8.9-9 10.5-16.4 22.4-21.9 35.1q1.4-0.9 2.9-1.8 6.1-3.6 12.7-6.3c-3.2 9.7-4.3 20.5-5.9 30.2-1.7 9.8-3.4 20.7-6 30.4h0.1c-2.6 1.9-5.4 6.1-6 8.1-2.2 6.7-2.9 15.3-2.8 22.3 0.1 7.3 1.1 16.3 2.3 24.4-8.7-7.3-18.1-13.7-28-19.3-7.6-4.1-24.9-15.9-34.3-19.5q-0.5-1.7-1.2-3.3c-2.8-5.9-7.5-12.5-11.5-17.6l-0.4 0.1-0.6-1.8c0 0-6.5-29.7-9.5-39.5-1.9-6.4-3.5-12.6-5.5-19.1-1.4-4.4-4.8-15.8-4.8-15.8l29.2-11.5c0 0-22.1-3.1-28.5-3.3-4.3-0.1-8.9-0.4-13.5-0.6q2.7-2.3 5.1-4.9c1.7-1.8-0.4-4.3-2.5-3.7-14.4 4.4-33.7-1.7-43.4 13.2q-0.6 0.9-1.4 1.5c-7.2 1.5-10.9 9.8-12.3 16.8-0.7 3.8-0.9 7.7-0.7 11.6-5.6 1.8-9.9 4.1-12.4 7.1-3.7 4.6-5 13.5 1.6 17.8 4.8 3 11.3 1.4 16.5-0.4 1.1 3.1 2.4 6.2 3.6 9.2 0.9 2.2 2.7 5.5 5 5.3 0.9 5 2.5 12.1 2.5 12.1l2.4 13.5q-0.7 0.1-1.3 0.3c-1.2 5.2-0.6 13-0.4 18.4 0.1 5.3-3.8 10.2-5.8 15-4.2 10.1-8.3 17.5-12.4 27.9-2.9 7.4-13.9 36.8-16.8 44.7l-1.4 3.6c-8-8.6-14.1-19.4-19.6-29.4z"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m186.2 274.3c3.3 7.9 5.7 17.4 6.1 25.9 0.2 5.7 0.9 11.4 0.9 17.1 0 4.4-1.5 9.1-1.1 13.3 3.9-7 16.6-18 24.3-8.4 8.4 10.6 1.3 31.7-2.1 42.5 1.8-2.6 4.8-9.2 8.7-7.8 2.1 0.7 3.5 3.8 4.1 5.7 1.3 3.7 0.5 7.3 0 11.3-0.5 3.9-1.5 12.8 0.4 16.7"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m73.3 376c2.1-8 5.1-15.5 7.9-23.2 2.9-7.9 14-37.3 16.9-44.7 4-10.4 8.2-17.8 12.4-27.9 2-4.8 5.9-9.7 5.7-15-0.1-5.4-0.7-13.2 0.4-18.4 2.5-1 8.1 0.6 15.1 0.6 7 0 18.1-1.9 26.3-2.7 10-0.9 19.9-2.9 29.6-5.8 4 5 8.8 11.7 11.5 17.6 3.6 7.6 2.8 21.1 0.2 22.2-2.6 1.1-14-17.1-19.2-22.1-1.4 4.7-0.5 28.1-3.7 29.6-3.3 1.5-10.6-0.3-15.6-1.9-4.9-1.5-24-8.8-36.2-15.9"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m269.1 243.7c-3-0.1-5.2 5.6-6 8.2-2.2 6.7-2.9 15.3-2.8 22.3 0.2 14 3.7 34.3 6.1 42.5 2.4 8.1 7.9 13.7 12.2 12 4.4-1.7 4.6-9 1.8-13.8-2.8-4.7-10.3-10-16.9-15.6-9-7.6-18.7-14.3-28.9-20-7.6-4.1-24.9-15.9-34.3-19.5"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m118 246.8l-2.5-13.8c0 0-2.1-9.4-2.8-14.1-0.5-3.5-0.1-9-1.6-12-1-2.3 0.3-1.4-2.1-1.7-1.5-0.2-3 0.7-4.4 1.1-5.2 1.8-11.8 3.5-16.6 0.4-6.6-4.2-5.3-13.2-1.6-17.7 4.1-5.1 13.3-7.9 25.1-10.3 0.9-1.5 0.1-5.6 0.5-7.7 0.5-2.8 1-5.2 2.3-7.7 2.7-5.3 6-8.3 10.9-11.1 12.5-7.2 28.4-4.6 42.3-4.2 6.4 0.2 28.5 3.2 28.5 3.2l-29.2 11.6c0 0 3.4 11.3 4.8 15.8 2 6.4 3.6 12.6 5.5 19.1 3 9.8 9.5 39.4 9.5 39.4"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m135.3 185.3c1.9 2.2 10.3 5.4 11.7 2"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m145.5 160.2c0.6 1.6 1.4 3.1 2.4 4.4"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m152 156.7q1.4 1.4 2.4 3.2"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m96.3 196.7c2.3-1.2 6.1-0.3 8.7-0.6"/>
		<path id="Layer" fill="#333333" d="m111.2 206.9c1.4 3 1.1 8.5 1.6 12q0.1 0.9 0.3 2c-2.3 0.2-4.1-3.1-5-5.3-1.3-3-2.5-6.1-3.7-9.2h0.2c1.4-0.5 2.9-1.3 4.4-1.2 2.4 0.3 1.1-0.6 2.2 1.7z"/>
		<path id="Layer" fill="#333333" d="m159.1 142.5c-6.2 6.8-14 12-22.6 15.2-4.9 1.8-11.8 4.1-17.6 3.1 2.4 9.8 1.9 20.1-1.4 29.6-0.8 2.2-4.3 1.3-4.4-0.9-0.1-4-3.2-6.8-5.5-9.8q0-0.1 0-0.1c-3.2 0.7-6.2 1.5-8.8 2.3-0.3-3.9 0-7.8 0.7-11.6 1.4-7.1 5.1-15.3 12.3-16.8q0.8-0.6 1.4-1.5c9.7-14.9 29-8.8 43.4-13.2 2.1-0.6 4.1 1.9 2.5 3.7z"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m269.4 243.6c7.2 0.1 14.4 0.9 21.4 2.4 9 2.2 17.7 5.6 25.8 10.2 7 4.1 8.9 5.8 12 13.5 3.2 7.6 5.9 16.7-1 23.4 6.4 2.9 15 16.5 7.1 22.3 1.9 1.1 4.2 1.8 6.1 3.1 4.6 3 8.3 6.6 10.7 11.1"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m269 243.7c2.7-9.6 4.4-20.5 6-30.3 2.4-14.7 3.7-32 13.1-44.3 11.2-14.9 32.4-19.9 50.6-20.2 18.8-0.3 27.9 6.6 21.4 12.6-2.8 2.5-10.7 0.8-14.6 1.3-4.9 0.7-10.4 1.1-14.5 3.3"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m274.2 219.1c9.4-1.1 16.7 0.9 25.4 3.6 4.4 1.4 15.8 4.3 17.7 8.2 1.9 3.7 1.4 25.7 1.4 25.7"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m299 173.4c3.6-2.9 7.4-4.1 12.4-4.1 5.6 0 11 1.4 15.9 4.1 3.2 1.8 9.5 9.6 3.4 12.4-1.8 0.8-6.7-1.6-8.7-1.8-3-0.3-7.5-0.3-10 1.4 7.1 0.3 11.1 5.5 9.1 12.7"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m360.1 161.5c14.8 3.1 17.3 16.7 7.5 17.8-8.7 1.1-16.3-4.3-26.7-2.8q-0.1 0.2 0 0.3 0.1 0.2 0.2 0.3c6.8-0.2 31.7 7.1 29.6 17.2-2.1 10.3-18.4-5-28.1-6.1 7.2 2.5 10.9 6.1 14.3 10.6 1.9 2.6 5.2 9.3 1.9 12.8-3 3.2-6.7-1.3-8.3-3.6-3.3-4.7-7-9.6-13-10.9 1.1 5.9-6.7 14.2-12.2 15.8-4.6 1.3-10 3.1-13.2-1.5"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m317.7 214.7c1.4 3.8-2.1 9.9-1 14.2"/>
	</g>
	<g className='shape-1' id="kite">
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m295.7 143.3c6.5-8 16.9-16.6 25.5-22.1 8.9-5.6 17.5-11.7 26.6-16.9 7.8-4.6 27-13.9 41.9-19.1"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m358.5 128.7c3.4-2.5 7.3-5.3 10.8-7.5 4.3-2.6 8.8-4.8 13.1-7.3 6.7-3.9 13.9-7.2 20.9-10.4"/>
		<path id="Layer" fill='#5B2EBC' stroke='#5B2EBC' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3} d="m494.4 49c-1.3 3.1-3.4 5.8-6 7.9-7.7 6.5-15.3 12.9-22.7 19.9-6.5 6.1-29.7 22.2-29.7 22.2 0 0-4.1-2.5-8.6-5.2-3.9 2.5-11.9 10-11.9 10-2.8-6.7-5.4-19.6-5.4-19.6-8-3.9-16.3-7.9-23-13 2-1.6 40.4-7.9 52.1-10.2 11.6-2.3 23.3-4.2 34.7-7.3 6.4-1.7 13.9-3.9 20.5-4.7z"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m387.1 71.2c6.7 5.1 15 9.1 23 13 2.9 1.5 5.7 3 8.4 4.5 2.1 1.2 5.6 3.2 8.9 5.1 4.5 2.7 8.6 5.2 8.6 5.2"/>
		<path id="Layer" fill='none' stroke='#333333' strokeLinecap='round' strokeLinejoin='round' strokeWidth={3}  d="m415.5 103.8c-2.8-6.7-5.4-19.6-5.4-19.6 3.7-0.4 14.1-4.5 17.7-5.9 5.6-2.1 25.2-9.8 31.4-11.5 3.4-0.9 5.1-3.7 8.2-4.9 2.2-0.9 4.2-0.2 5.8-2.2"/>
	</g>
</svg>
}