import React from "react";
import WaitListForm from "../../molecules/WaitlistForm";
import Text from "../../atoms/Text";
import crossLogo from "../../images/waitlistModal/crossLogo.svg";
import successLogo from "../../images/waitlistModal/successLogo.svg";
import Button from "../../atoms/Button";
type PROPS_TYPE = {
  close: () => void;
  success: boolean;
  setSuccess: (x: boolean) => void;
  isGetEarlyAccess?: boolean;
};

export default function WaitListModal(props: PROPS_TYPE) {
  if (props.success)
    return (
      <div className={"p-11"}>
        <div className={""}>
          <img
            src={crossLogo}
            className={"ml-auto cursor-pointer"}
            onClick={props.close}
            alt={"exit"}
          />
        </div>
        <div className={""}>
          <img
            src={successLogo}
            alt={"tick with green background"}
            className={"mx-auto"}
          />
        </div>
        <div className={"mt-4 md:mt-8 text-center"}>
          <Text text={"Wohoo!"} textStyle={"h2 font-bold !text-[#170F49]"} />
        </div>
        <div className={"mt-2.5 mb-8 text-center"}>
          <Text
            text={"Let's unlock your true growth potential together!"}
            textStyle={"text-base inter-500-32-40 text-[#170F49]"}
          />
        </div>
        <div>
          <Button
            id={"waitlist-modal"}
            variant={"primary"}
            className={"mx-auto !w-full sm:!w-[300px]"}
            onClick={props.close}
            height={56}
            width={300}
          >
            Continue to the website
          </Button>
        </div>
      </div>
    );
  return (
    <div
      className={
        "px-[20px] md:px-[35px] pt-[20px] md:pt-[43px] h-full md:h-auto overflow-scroll md:overflow-visible pb-28 md:pb-0"
      }
    >
      <div className={"flex "}>
        <div className={"  mr-auto"}>
          <div className={"leading-[32px] ml-[3px]"}>
            <Text text={"Join the Waitlist!"} textStyle={"mazz-500-24-32"} />
          </div>
          <div className={" leading-[20px] md:leading-[31px] ml-[3px]"}>
            <Text
              className={"text-p3 opacity-70"}
              text={
                "Convert monthly users to annual subscribers by becoming a SaaSPay seller"
              }
              textStyle={"b1"}
            />
          </div>
          <hr className={"mt-[24px] mb-[24px] bg-[#F3F3F3] border-[#F3F3F3]"} />
        </div>
        <div className={"cursor-pointer"} onClick={props.close}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 5.25L5.25 15.75"
              stroke="#B0B7C3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.25 5.25L15.75 15.75"
              stroke="#B0B7C3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <WaitListForm
        isGetEarlyAccess={props.isGetEarlyAccess}
        setSuccess={props.setSuccess}
      />
    </div>
  );
}
