import React, { useState } from "react";
import Layout from "../organisms/Layout";
import ContactUsForm from "../organisms/ContactUsForm";
import { PageSEO } from "../atoms/SEO";
export default function ContactUs({ location }: any) {
  return (
    <Layout>
      <PageSEO
        path={location.pathname}
        title={"Contact Us - Need More Help? SaaSPay Support to the Rescue"}
        description={
          "Get in touch with experts to answer your queries about managing SaaS expenses with SaaSPay. Contact Us today to know more."
        }
      />
      <ContactUsForm />
    </Layout>
  );
}
